import { ProgressContainer } from 'containers';

import { useNavigate, useParams } from 'react-router-dom';

import { useProfileImage } from '@guider-global/azure-storage-hooks';
import NoMatches from 'assets/no_relationships_avatar.svg';
import {
  getFormattedProgramFields,
  getSubDomain,
  getVisibleProfileFields,
} from '@guider-global/front-end-utils';
import {
  useBaseLanguage,
  useOrganization,
  useOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import {
  EProgramVariation,
  ICustomField,
  IMatchV2,
  IMembership,
  IProfile,
  IRelationship,
  ProfileSkill,
  Program,
} from '@guider-global/shared-types';
import { OrganizationProfileView } from '@guider-global/ui';
import {
  Button,
  CircularAvatarPair,
  CustomModal,
  Image,
  Loading,
  TextStack,
} from '@guider-global/ui/lib/components';

import { Stack } from '@guider-global/ui/lib/components/Layout/Stack';
import { MDIcons, SanityIcon } from 'components';
import { interpolate } from 'functions';
import { useLocalization } from 'hooks';
import { useMatchesV2 } from 'hooks/useMatchesV2';
import { useState } from 'react';
import { NoMatchesView } from 'views';
import { useAppDispatch } from 'store/hooks';
import { showAppAlert } from 'store/slices/appSlice';
import ConfettiExplosion from 'react-confetti-explosion';
import {
  useMemberships,
  useProfiles,
  useRelationships,
  useUsers,
} from '@guider-global/front-end-hooks';
import { ITypedApiResult } from '@guider-global/swr';

export function ProgramMatchesPage() {
  const { programSlug = '' } = useParams<{
    programSlug: string;
  }>();

  // State
  const [matchingLoading, setMatchingLoading] = useState<boolean>(false);
  const [selectedProfileId, setSelectedProfileId] = useState<
    string | undefined
  >(undefined);
  const [showMatchedModal, setShowMatchedModal] = useState<boolean>(false);
  const [selectedMatch, setSelectedMatch] = useState<{
    match: IMatchV2 | undefined;
    relationshipId: string | undefined;
  }>({ match: undefined, relationshipId: undefined });

  // Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const organizationSlug = getSubDomain();
  const { getProfileImage } = useProfileImage();

  // Organization

  const { organization } = useOrganization({
    organizationSlug,
  });

  // Profile
  const { profile } = useProfiles({});

  // User
  const { user } = useUsers({});
  // Base Language

  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });
  // Program
  const { getProgram } = useOrganizationPrograms({
    localeCode,
    organizationSlug,
  });
  const program = getProgram(programSlug);

  // Memberships
  const { memberships } = useMemberships({});

  const membership = memberships?.find(
    (membership) =>
      membership?.programSlug === programSlug && membership?.role === 'trainee',
  );

  // Strings and assets

  const matchesToDisplay = program?.registration?.matches_to_display;

  // Matching

  const { matchesV2: matches, isLoadingMatchesV2 } = useMatchesV2({
    query: `?resultType=guideMembership&organizationSlug=${organizationSlug}&programSlug=${programSlug}${
      matchesToDisplay ? `&pageLimit=${matchesToDisplay}` : ''
    }`,
  });

  // Relationships

  const { relationships, reqRelationships, isLoadingRelationships } =
    useRelationships({});

  // Relationship limits

  const isTraineeRelationshipLimitSet =
    program?.relationships?.relationship_limits
      ?.enable_trainee_relationship_limit ?? false;

  const traineeRelationshipLimit = isTraineeRelationshipLimitSet
    ? program?.relationships?.relationship_limits?.trainee_relationship_limit
    : undefined;

  const programRelationships =
    relationships?.filter((relationship) => {
      const relationshipTraineeProfileIds = (
        relationship?.traineeProfiles as IProfile[]
      ).map((relationship) => relationship?.id);

      const relationshipIncludesTrainee =
        profile && relationshipTraineeProfileIds.includes(profile?.id);
      return (
        relationship?.programSlug === programSlug &&
        !relationship?.isConcluded &&
        relationshipIncludesTrainee
      );
    }) ?? [];

  const showRegistrationLimitReachedModal =
    traineeRelationshipLimit !== undefined
      ? (programRelationships?.length ?? 0) >= traineeRelationshipLimit
      : false;

  const isRelationshipLimitModalOpen =
    !isLoadingRelationships &&
    !showMatchedModal &&
    showRegistrationLimitReachedModal;

  // Handle match

  const handleMatching = (id: string, match: IMatchV2) => {
    if (!membership) return;

    setMatchingLoading(true);
    setSelectedProfileId(id);
    const traineeProfile = (membership?.profile as Partial<IProfile>)?.id;

    reqRelationships(
      {
        method: 'PUT',
        data: {
          guideProfiles: [id],
          traineeProfiles: traineeProfile ? [traineeProfile] : [],
          organizationSlug,
          programSlug,
          programTypeSlug:
            program?.program_details?.program_type?.metadata?.id?.current,
          programVariationTypeSlug: EProgramVariation.Individual,
          profileId: (membership?.profile as Partial<IProfile>)?.id,
        },
      },
      {
        onError: (err) => {
          setMatchingLoading(false);
          dispatch(
            showAppAlert({
              severity: 'error',
              message: err?.message,
              timeout: 5000,
            }),
          );
        },
        onSuccess: (result) => {
          const relationshipId = (result?.data as IRelationship[])[0]?.id;

          setSelectedMatch({
            match,
            relationshipId,
          });
          setShowMatchedModal(true);
          setMatchingLoading(false);
        },
        populateCache: (result, currentData) => {
          const resultIds = result.data.map((relationship) => relationship.id);
          const filterOutNewRelationships =
            currentData?.data.filter((relationship) => {
              return !resultIds.includes(relationship.id);
            }) ?? [];

          const newCache = {
            ...currentData,
            data: [...filterOutNewRelationships, ...result.data],
          } as ITypedApiResult<IRelationship[]>;
          return newCache;
        },
      },
    );
  };

  if (!baseLanguage)
    return (
      <ProgressContainer percentage={100}>
        <></>
      </ProgressContainer>
    );

  if (!matches || isLoadingMatchesV2)
    return (
      <ProgressContainer
        percentage={100}
        onBackButtonClick={() => navigate(-1)}
        endAdornment={
          <Button
            variant="text"
            onClick={() => navigate('/dashboard')}
            label={baseLanguage?.globals?.common?.skip_button_label}
          />
        }
      >
        <Loading color="secondary" />
      </ProgressContainer>
    );

  if (matches?.length === 0 && !isLoadingMatchesV2) {
    const title = interpolate(
      baseLanguage?.registration?.open_matching?.matches?.empty_state_title,
      {
        rolePluralized:
          organization?.open_matching?.program_type?.program_type_text?.common
            ?.guide?.pluralized ?? 'Mentors',
        roleSingular:
          organization?.open_matching?.program_type?.program_type_text?.common
            ?.guide?.singular ?? 'Mentor',
      },
    );
    return (
      <ProgressContainer
        onBackButtonClick={() => navigate(-1)}
        endAdornment={
          <Button
            variant="text"
            onClick={() => navigate('/dashboard')}
            label={baseLanguage?.globals?.common?.skip_button_label}
          />
        }
        percentage={100}
      >
        <NoMatchesView
          imageSrc={NoMatches}
          title={title}
          description={
            baseLanguage?.registration?.open_matching?.matches
              ?.empty_state_description
          }
          buttons={[
            {
              key: 'matches-btn-1',
              variant: 'outlined',
              label: baseLanguage?.globals?.common?.go_back_button_label,
              color: 'info',
              onClick: () => navigate(-1),
            },
            {
              key: 'matches-btn-2',
              variant: 'contained',
              label: baseLanguage?.globals?.common?.continue_button_label,
              color: 'info',
              onClick: () => navigate('/dashboard'),
            },
          ]}
        />
      </ProgressContainer>
    );
  }

  // Match modal

  const matchImage =
    getProfileImage({
      profilePicture: selectedMatch?.match?.profile?.picture,
      userPicture: selectedMatch?.match?.user?.picture,
    }) ?? '';

  const userImage =
    getProfileImage({
      profilePicture: profile?.picture,
      userPicture: user?.picture,
    }) ?? '';

  return (
    <ProgressContainer
      onBackButtonClick={() => navigate(-1)}
      endAdornment={
        <Button
          variant="text"
          onClick={() => navigate('/dashboard')}
          label={baseLanguage?.globals?.common?.skip_button_label}
        />
      }
    >
      <Stack
        direction={'column'}
        justifyContent={'flex-start'}
        width={{ xs: '90%', md: '832px' }}
        pt={{ xs: 4, md: 2 }}
      >
        <TextStack
          spacing={1.5}
          size="medium"
          heading={{
            text: interpolate(
              baseLanguage?.registration?.open_matching?.matches?.title,
              {
                matchesToDisplay:
                  matchesToDisplay && matchesToDisplay !== 10
                    ? matchesToDisplay?.toString()
                    : '',
                roleSingular:
                  program?.program_details?.program_type?.program_type_text
                    ?.common?.guide?.singular ?? 'Mentor',
              },
            ),
            variant: 'h2',
            lineHeight: '115%',
          }}
          subtitles={[
            {
              text: baseLanguage?.registration?.open_matching?.matches
                ?.description,
              color: 'text.secondary',
              variant: 'subtitle1',
            },
          ]}
        />

        {matches?.map((match, index) => {
          const profileFieldsData =
            getVisibleProfileFields(
              organization?.profile_fields,
              match?.profile?.organizationFields as ICustomField[],
            ) ?? [];

          const profileFields = profileFieldsData?.map((field) => {
            return {
              icon: <SanityIcon name={field?.icon?.name as MDIcons} />,
              label: field?.label?.toString() ?? '',
            };
          });

          const image = getProfileImage({
            profilePicture: match?.profile?.picture,
            userPicture: match?.user?.picture,
          });

          const buttonLabel = interpolate(
            program?.program_details?.program_type?.program_type_text
              ?.variations?.individual?.registration?.registration_trainee
              ?.registration_trainee_choose_a_guide
              ?.registration_trainee_choose_a_guide_choose_guide_button_label ??
              '',
            { name: match?.profile?.firstName ?? '' },
          );

          const bottomText =
            program?.relationships?.relationship_limits
              ?.enable_guide_relationship_limit &&
            program?.relationships?.relationship_limits
              ?.guide_relationship_limit &&
            match?.relationshipSpotsLeft &&
            interpolate(baseLanguage?.profile_card?.spots_left_label, {
              guideSingular:
                program?.program_details?.program_type?.program_type_text
                  ?.common?.guide?.singular ?? '',
              spotNumber: match?.relationshipSpotsLeft?.toString(),
              spot:
                match.relationshipSpotsLeft === 1
                  ? baseLanguage?.profile_card?.spot_singular
                  : baseLanguage?.profile_card?.spots_plural,
            });

          const matchText =
            match?.percentage &&
            interpolate(baseLanguage?.profile_card?.match_percentage, {
              matchPercentage: match?.percentage.toFixed(0),
            });

          const skills = match?.skills?.map((skill, index) => {
            const allSkills = [
              ...(program?.registration?.skills?.soft_skills ?? []),
              ...(program?.registration?.skills?.hard_skills ?? []),
            ] as ProfileSkill[];

            let label =
              allSkills?.find((s) => s?.slug === skill?.fieldSlug)?.label ??
              undefined;

            if (label === '' || !label) {
              label =
                program?.registration?.skills?.options?.find(
                  (s) => s?.id?.current === skill?.fieldSlug,
                )?.label ?? undefined;
            }

            return {
              key: `${'label'}-${index}`,
              isMatched: skill?.matched ?? false,
              value: label ?? '',
            };
          });

          const goalsSource =
            program?.registration?.goals?.custom_goal_categories ??
            organization?.goal_categories?.categories;

          const goals = match?.goalCategories.map((goalCategory, index) => {
            const label = goalsSource?.find(
              (g) => g?.goal_category_slug?.current === goalCategory?.fieldSlug,
            )?.goal_category_name;

            return {
              key: `${'label'}-${index}`,
              isMatched: goalCategory?.matched ?? false,
              value: label ?? '',
            };
          });

          const matchSkills =
            match?.skills?.length !== 0
              ? {
                  title: baseLanguage?.profile_card?.matched_on_skills_label,
                  skills,
                }
              : undefined;

          const matchGoals =
            match?.goalCategories?.length !== 0
              ? {
                  title: baseLanguage?.profile_card?.matched_on_goals_label,
                  goals,
                }
              : undefined;

          const customFields = getFormattedProgramFields(
            match?.membership as IMembership,
            program as Program,
          );

          return (
            <OrganizationProfileView
              key={match?.profile?.id}
              customFields={customFields}
              profileFields={profileFields}
              guideReviews={{
                reviewsPlural: baseLanguage?.profile_card?.reviews_plural,
                reviewsSingular: baseLanguage?.profile_card?.reviews_singular,
                buttonLabel: baseLanguage?.profile_card?.reviews_button_label,
                noReviewsText: baseLanguage?.profile_card?.no_reviews_yet_label,
                reviews: match?.reviews,
              }}
              displayName={match?.profile?.displayName ?? '  '}
              score={parseFloat(match?.percentage?.toFixed(2))}
              matchText={matchText}
              isBestMatch={index === 0}
              bestMatchLabel={
                baseLanguage?.programs?.program_registration?.best_match_label
              }
              subtitles={[
                match?.profile?.jobTitle && {
                  text: match?.profile?.jobTitle,
                  variant: 'subtitle1',
                },
                match?.profile?.townOrCity && {
                  text: `${match?.profile?.townOrCity}`,
                  variant: 'caption',
                  color: 'text.secondary',
                },
              ]}
              avatarSrc={image}
              skills={matchSkills}
              goals={matchGoals}
              linkedInUrl={match?.profile?.linkedInUrl}
              viewLinkedInLabel={
                baseLanguage?.programs?.program_registration
                  ?.linkedin_profile_button_label
              }
              buttons={[
                {
                  label: buttonLabel,
                  key: '1',
                  variant: 'contained',
                  color: 'info',
                  sx: { px: 3 },
                  onClick: () =>
                    handleMatching(match?.profile?.id ?? '', match),
                  loading:
                    matchingLoading && selectedProfileId === match?.profile?.id,
                  disabled:
                    matchingLoading && selectedProfileId !== match?.profile?.id,
                },
              ]}
              bottomText={bottomText}
            />
          );
        })}
      </Stack>
      <CustomModal isOpen={isRelationshipLimitModalOpen}>
        <Stack
          direction={'column'}
          gap={2}
          justifyContent={'space-between'}
          alignItems={'center'}
          px={5}
          py={4}
          width={'380px'}
        >
          <TextStack
            align="center"
            size="large"
            heading={
              program?.program_details?.program_type?.program_type_text
                ?.variations?.individual?.registration?.registration_trainee
                ?.registration_trainee_guide_limit_modal
                ?.registration_trainee_guide_limit_modal_title
            }
            subtitles={[
              program?.program_details?.program_type?.program_type_text
                ?.variations?.individual?.registration?.registration_trainee
                ?.registration_trainee_guide_limit_modal
                ?.registration_trainee_guide_limit_modal_description,
            ]}
          />
          <Image src={NoMatches} fit="contain" width={'70%'} />
          <Button
            fullWidth
            label={
              program?.program_details?.program_type?.program_type_text
                ?.variations?.individual?.registration?.registration_trainee
                ?.registration_trainee_guide_limit_modal
                ?.registration_trainee_guide_limit_modal_button_label
            }
            variant={'contained'}
            color="info"
            onClick={() => navigate(`/relationships`)}
          />
        </Stack>
      </CustomModal>
      <CustomModal isOpen={showMatchedModal}>
        <Stack direction={'row'} gap={0} spacing={0}>
          <ConfettiExplosion force={0.8} duration={5000} />
          <Stack
            direction={'column'}
            gap={2}
            justifyContent={'space-between'}
            alignItems={'center'}
            px={5}
            py={4}
            width={'380px'}
          >
            <TextStack
              align="center"
              size="large"
              heading={
                program?.program_details?.program_type?.program_type_text
                  ?.variations?.individual?.registration?.registration_trainee
                  ?.registration_trainee_relationship_created_successfully
                  ?.registration_trainee_relationship_created_successfully_title
              }
              subtitles={[
                interpolate(
                  program?.program_details?.program_type?.program_type_text
                    ?.variations?.individual?.registration?.registration_trainee
                    ?.registration_trainee_relationship_created_successfully
                    ?.registration_trainee_relationship_created_successfully_description ??
                    '',
                  {
                    firstName: selectedMatch?.match?.profile?.firstName || '',
                    lastName: selectedMatch?.match?.profile?.lastName || '',
                  },
                ),
              ]}
            />
            <CircularAvatarPair
              mainAvatar={matchImage}
              secondaryAvatar={userImage}
            />
            <Button
              fullWidth
              label={baseLanguage?.globals?.relationships?.view_relationship}
              variant={'contained'}
              color="info"
              onClick={() =>
                navigate(`/relationships/${selectedMatch?.relationshipId}`)
              }
            />
          </Stack>
          <ConfettiExplosion force={0.8} duration={5000} />
        </Stack>
      </CustomModal>
    </ProgressContainer>
  );
}
