// external
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Box,
  Divider,
  Modal,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Control, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

// internal
import {
  EProgramVariation,
  ICustomField,
  IMembership,
  ISkill,
  ProgramRegistrationSkills,
} from '@guider-global/shared-types';
import { LoadingButton } from '@mui/lab';
import { ModalCard, PageCard } from 'components';
// Hooks
import {
  useBaseLanguage,
  useOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import {
  useLocalization,
  useMixpanelEvents,
  useMobileMediaQuery,
  useTrackEvent,
} from 'hooks';

// Store
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { hideNavbar } from 'store/slices/appSlice';
import {
  IMembershipForm,
  selectMembershipTraineeForm,
  setMembershipTraineeForm,
} from 'store/slices/formsSlice';
// utils
import {
  getISOStringWithoutMilliseconds,
  getSubDomain,
} from '@guider-global/front-end-utils';
import {
  renderFields,
  renderSkills,
} from 'pages/ProgramRegistrationPage/utils';
import { areArraysEqual } from 'utils';
import {
  useCustomFields,
  useMemberships,
  useProfiles,
} from '@guider-global/front-end-hooks';

export const TraineeRegistrationAboutYouPage: React.FC = () => {
  const [isFormReady, setIsFormReady] = useState<boolean>(false);

  const theme = useTheme();
  const isMobile = useMobileMediaQuery();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const { trackMixpanelEvent, trackScreenTime, unionUserProperty } =
    useMixpanelEvents();

  const { profile } = useProfiles({});
  const profileId = profile?.id;
  const { programSlug = '' } = useParams<{
    programSlug: string;
  }>();

  const { getProgram } = useOrganizationPrograms({
    organizationSlug,
    localeCode,
  });
  const program = getProgram(programSlug);

  const {
    isLoadingMemberships,
    reqMemberships,
    memberships,
    isMutatingMemberships,
    membershipsRevalidate,
  } = useMemberships({});

  const {
    reqCustomFields,
    isLoadingCustomFields,
    isMutatingCustomFields,
    customFieldsRevalidate,
  } = useCustomFields({});

  const membershipTraineeForm = useAppSelector((state) =>
    selectMembershipTraineeForm(state, programSlug),
  );

  const disableTraineeRegistration =
    program?.program_details?.trainee_registration_rules
      ?.disable_trainee_registration ?? false;

  useTrackEvent({
    track: {
      eventKey: 'program-trainee-registration-visited',
      eventParams: {
        organization: { organizationSlug },
        program: {
          organizationSlug,
          programSlug,
        },
      },
    },
    for: programSlug,
    if: !disableTraineeRegistration,
  });

  useEffect(() => {
    if (!disableTraineeRegistration) {
      trackMixpanelEvent('Trainee Program Registration - Viewed', {
        'Date viewed': getISOStringWithoutMilliseconds(),
        'Program ID': programSlug,
      });
    }
  }, [trackMixpanelEvent, programSlug, disableTraineeRegistration]);

  useEffect(() => {
    dispatch(hideNavbar(true));
  }, [dispatch]);

  const programMemberships = memberships?.filter(
    (membership) => membership.programSlug === programSlug,
  );

  const traineeMembership = programMemberships?.find(
    (membership) => membership.role === 'trainee',
  );

  const skillsConfig = useMemo(
    () => program?.registration?.skills as ProgramRegistrationSkills,
    [program],
  );

  const skillsOptions = useMemo(
    () => skillsConfig?.options?.map((skill) => skill.id.current) ?? [],
    [skillsConfig],
  );

  const membershipProgramFields = useMemo(
    () => (traineeMembership?.programFields as ICustomField[]) ?? [],
    [traineeMembership],
  );

  const defaultFormFields = useMemo(() => {
    const programSkills =
      (traineeMembership?.skills as Partial<ISkill>[]) ?? [];

    return {
      ...(programSkills.length !== 0 && {
        skills: programSkills
          .map((skill) => skill.fieldSlug)
          .filter(
            (skillValue) => skillValue && skillsOptions.includes(skillValue),
          ),
      }),
      ...Object.fromEntries(
        membershipProgramFields.map((customField) => [
          customField.fieldSlug,
          customField.value,
        ]),
      ),
    };
  }, [membershipProgramFields, traineeMembership, skillsOptions]);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    reset,
    trigger,
  } = useForm({
    mode: 'onChange',
    defaultValues: membershipTraineeForm || defaultFormFields,
  });
  const typedControl = control as Control;

  useEffect(() => {
    if (!isFormReady && Object.keys(defaultFormFields).length !== 0) {
      reset(defaultFormFields);

      setTimeout(() => {
        trigger();
      }, 1);

      setIsFormReady(true);
    }
  }, [isFormReady, defaultFormFields, reset, trigger]);

  if (!program) {
    return <></>;
  }

  const isGroupProgram =
    program.program_details?.program_variation === EProgramVariation.Group;
  const programType = program.program_details?.program_type;
  const programTypeText = programType?.program_type_text;
  const programVariation = programTypeText?.variations?.individual;

  const registrationQuestions =
    program.registration?.registration_questions?.filter(
      (registrationQuestion) =>
        ['trainee', 'both'].includes(registrationQuestion.audience),
    );

  const isSkillsEnabled = program.registration?.skills?.enable_skills_question;

  const publishMembership = async (data: IMembershipForm) => {
    let customFields: ICustomField[] = [];

    const customFieldsPostData: Partial<ICustomField>[] = Object.entries(data)
      .filter(([fieldKey]) => fieldKey !== 'skills')
      .map(([fieldKey, fieldValue]) => {
        const fieldType = program.registration?.registration_questions?.find(
          (registrationQuestion) =>
            registrationQuestion.id.current === fieldKey,
        )?.type;

        return {
          fieldSlug: fieldKey,
          organizationSlug: organizationSlug,
          programSlug: programSlug,
          fieldType,
          value: fieldValue,
          profileId,
        };
      });

    if (customFieldsPostData && customFieldsPostData.length !== 0) {
      const customFieldsResponse = await reqCustomFields({
        method: 'POST',
        url: '/customfields',
        data: [...customFieldsPostData],
      });

      Array.isArray(customFieldsResponse.data) &&
        (customFields = customFieldsResponse.data);
    }

    const customFieldIds: string[] | undefined = customFields.map(
      (customField) => customField.id,
    );

    const membershipData: Partial<IMembership> & { skillSlugs: string[] } = {
      role: 'trainee',
      organizationSlug,
      programSlug,
      profile: profileId,
      isPublished: true,
      programFields: customFieldIds ?? [],
      skillSlugs: data.skills ?? [],
      programVariationTypeSlug: EProgramVariation.Individual,
    };

    await reqMemberships({
      method: 'POST',
      url: '/memberships',
      data: membershipData,
    });
    membershipsRevalidate();

    navigate('./goals');
  };

  const updateMembership: SubmitHandler<IMembershipForm> = async (data) => {
    let newCustomFieldIds: string[] = [];

    const { isPublished } = data;
    delete data.isPublished;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { skills, ...newCustomFields } = data;

    if (Object.keys(newCustomFields).length !== 0) {
      let customFieldsPostData: ICustomField[] = [];

      await Promise.all(
        Object.entries(newCustomFields).map(
          async ([newCustomFieldSlug, newCustomFieldValue]) => {
            const oldMembershipProgramField = membershipProgramFields.find(
              (membershipProgramField) =>
                membershipProgramField.fieldSlug === newCustomFieldSlug,
            );
            const oldValue = oldMembershipProgramField?.value;

            const isNew = !oldValue;

            if (!isNew) {
              let hasValueChanged = false;

              const fieldId = oldMembershipProgramField.id;
              const fieldType = oldMembershipProgramField.fieldType;

              const newValue = newCustomFieldValue;

              if (fieldType === 'multi-select') {
                if (Array.isArray(newValue) && Array.isArray(oldValue)) {
                  hasValueChanged = !areArraysEqual(newValue, oldValue);
                }
              } else {
                hasValueChanged = oldValue !== newValue;
              }

              if (!hasValueChanged) {
                return;
              }

              return await reqCustomFields({
                method: 'PATCH',
                url: `/customfields/${fieldId}`,
                data: {
                  value: newValue,
                  fieldType,
                },
              });
            } else {
              const currentRegistrationQuestion = registrationQuestions?.find(
                (registrationQuestion) =>
                  registrationQuestion.id.current === newCustomFieldSlug,
              );

              return customFieldsPostData.push({
                fieldSlug: newCustomFieldSlug,
                organizationSlug,
                fieldType: currentRegistrationQuestion?.type,
                value: newCustomFieldValue,
                profileId,
                programSlug,
              } as ICustomField);
            }
          },
        ),
      );

      if (customFieldsPostData?.length !== 0) {
        const { data: customFieldsResponseData } = await reqCustomFields({
          method: 'POST',
          url: '/customfields',
          data: [...customFieldsPostData],
        });

        if (customFieldsResponseData) {
          newCustomFieldIds = customFieldsResponseData.map(
            (customField) => customField.id,
          );
        }

        customFieldsRevalidate();
      }
    }

    const membershipData: Partial<IMembership> & { skillSlugs: string[] } = {
      isPublished: !!isPublished,
      programSlug,
      skillSlugs: skills ?? [],
      ...(newCustomFieldIds && {
        programFields: [
          ...membershipProgramFields.map((field) => field.id),
          ...newCustomFieldIds,
        ],
      }),
    };

    await reqMemberships({
      method: 'PATCH',
      url: `/memberships/${traineeMembership?.id}`,
      data: membershipData,
    });

    membershipsRevalidate();

    navigate('./goals');
  };

  const onSubmit: SubmitHandler<IMembershipForm> = async (data) => {
    try {
      dispatch(setMembershipTraineeForm({ programSlug, data }));

      if (traineeMembership) {
        await updateMembership(data);
      } else {
        await publishMembership(data);
        trackScreenTime('Trainee Program Registration - About you Completed', {
          'Program type': programTypeText?.common?.verb,
          'Program ID': programSlug,
        });
        unionUserProperty(
          'Trainee profiles',
          `${organizationSlug}-${programTypeText?.common?.verb}`,
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Paper
        sx={{
          pt: isMobile ? 0 : 4,
          pb: 4,
          borderTop: `8px solid ${theme.palette.secondary.main}`,
          ...(isMobile && { borderRadius: '0px' }),
        }}
      >
        <PageCard
          title={
            programVariation?.registration?.registration_trainee
              ?.registration_trainee_about_you
              ?.registration_trainee_about_you_title ?? ''
          }
          subtitle={
            programVariation?.registration?.registration_trainee
              ?.registration_trainee_about_you
              ?.registration_trainee_about_you_description ?? ''
          }
        />
        {!isMobile && <Divider sx={{ my: 4, mx: 3 }} />}
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ padding: isMobile ? '0 16px' : '0 24px' }}
        >
          {isSkillsEnabled &&
            renderSkills({
              skillsConfig,
              typedControl,
              errors,
              pageName: 'TraineeRegistrationAboutYouPage',
              role: 'trainee',
              isGroupProgram,
            })}
          {renderFields({
            registrationQuestions,
            isSkillsEnabled,
            typedControl,
            errors,
            pageName: 'TraineeRegistrationAboutYouPage',
            role: 'trainee',
            isGroupProgram,
          })}
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              mt: 6,
            }}
          >
            <LoadingButton
              variant="contained"
              color="info"
              size="large"
              type="submit"
              disabled={!isValid}
              sx={{
                color: isValid ? 'white' : 'inherit',
              }}
              fullWidth={isMobile}
              loading={
                isLoadingCustomFields ||
                isLoadingMemberships ||
                isMutatingMemberships ||
                isMutatingCustomFields
              }
              data-cy={`pages_TraineeRegistrationAboutYouPage_continue-button`}
            >
              {baseLanguage?.globals?.common?.continue_button_label ??
                'Continue'}
            </LoadingButton>
          </Box>
        </form>
      </Paper>

      <Modal open={disableTraineeRegistration}>
        <ModalCard
          title={
            <Box
              sx={{
                display: 'flex',
                flexFlow: 'row nowrap',
                gap: 1,
                alignItems: 'center',
              }}
            >
              <ErrorOutlineIcon color="warning" />
              {program.program_details?.trainee_registration_rules
                ?.modal_title ?? ''}
            </Box>
          }
          headerLineColor="warning"
          actions={[
            {
              label:
                program.program_details?.trainee_registration_rules
                  ?.return_to_dashboard_button_label ?? '',
              action: () => navigate('/dashboard'),
              variant: 'contained',
              color: 'info',
            },
          ]}
        >
          <Typography
            variant="body1"
            color="text.primary"
            sx={{ whiteSpace: 'pre-wrap' }}
          >
            {program.program_details?.trainee_registration_rules
              ?.modal_description ?? ''}
          </Typography>
        </ModalCard>
      </Modal>
    </>
  );
};
