import { ProgressContainer } from 'containers';

import { ProgramMembershipForm } from 'forms/ProgramMembershipForm';
import { useNavigate } from 'react-router-dom';

export function ProgramMembershipPage() {
  const navigate = useNavigate();
  return (
    <ProgressContainer onBackButtonClick={() => navigate(-1)} percentage={25}>
      <ProgramMembershipForm />
    </ProgressContainer>
  );
}
