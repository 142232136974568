// external
import PersonSearchSharp from '@mui/icons-material/PersonSearchSharp';
import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Fade,
  Modal,
  ThemeProvider,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// internal
import { deepMerge, hexToRGB } from 'utils';

// components
import { AvatarUser, MDIcons, PageCard, SanityIcon } from 'components';

// store
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  hideConfetti,
  showAppAlert,
  showConfetti,
} from 'store/slices/appSlice';
import {
  selectMembershipTraineeForm,
  setMembershipTraineeForm,
} from 'store/slices/formsSlice';

// hooks
import {
  useGoals,
  useLocalization,
  useMatches,
  useMixpanelEvents,
  useMobileMediaQuery,
  useTrackEvent,
} from 'hooks';

// types
import {
  EProgramVariation,
  ICustomField,
  IMatch,
  IMatchMembership,
  IMatchedGoalCategory,
  IMatchedSkill,
  IMembership,
  IProfile,
  IRelationship,
} from '@guider-global/shared-types';

import { useProfileImage } from '@guider-global/azure-storage-hooks';
import {
  getFormattedProgramFields,
  getSubDomain,
  getVisibleProfileFields,
} from '@guider-global/front-end-utils';
import {
  buildSanityImageUrl,
  useBaseLanguage,
  useOrganization,
  useOrganizationPrograms,
  useSettings,
} from '@guider-global/sanity-hooks';
import {
  IProfileViewField,
  OrganizationProfileView,
  theme,
} from '@guider-global/ui';
import { interpolate } from 'functions';
import { RegistrationLimitReachedModal } from 'modals';

import {
  useMemberships,
  useProfiles,
  useRelationships,
} from '@guider-global/front-end-hooks';
import countries from 'i18n-iso-countries';
import { ITypedApiResult } from '@guider-global/swr';

export const TraineeRegistrationChooseGuidePage: React.FC = () => {
  const organizationTheme = useTheme();
  const combinedPalette = deepMerge(
    theme.appTheme.palette,
    organizationTheme.palette,
  );

  const navigate = useNavigate();
  const { trackScreenTime } = useMixpanelEvents();
  const organizationSlug = getSubDomain();

  const dispatch = useAppDispatch();
  const [relationshipId, setRelationshipId] = useState('');
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chosenGuide, setChosenGuide] = useState<{
    userPicture?: string;
    profile?: Partial<IProfile>;
  }>();
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);

  const { membershipsRevalidate } = useMemberships({});
  const { profiles } = useProfiles({});
  const { revalidateGoals } = useGoals({});
  const profile = profiles?.at(0);
  const profileId = profile?.id ?? '';

  const { settings } = useSettings({
    localeCode: 'en_GB',
  });

  const isMobile = useMobileMediaQuery();

  // base lanuage
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const { programSlug = '', role } = useParams<{
    programSlug: string;
    role: 'guide' | 'trainee';
  }>();

  const { getProfileImage } = useProfileImage();

  countries.registerLocale(require(`i18n-iso-countries/langs/en.json`));

  const membershipTraineeForm = useAppSelector((state) =>
    selectMembershipTraineeForm(state, programSlug),
  );
  const membershipTraineeGoals = membershipTraineeForm?.goals || [];

  useTrackEvent({
    track: {
      eventKey: 'program-trainee-viewed-guide-list',
      eventParams: {
        organization: { organizationSlug },
        program: { organizationSlug, programSlug, includeChooseGuideUrl: true },
      },
    },
  });

  const {
    relationships,
    isLoadingRelationships,
    reqRelationships,
    isMutatingRelationships,
    isValidatingRelationships,
  } = useRelationships({});

  const { getProgram } = useOrganizationPrograms({
    organizationSlug,
    localeCode,
  });
  const program = getProgram(programSlug);

  const matchesToDisplay = program?.registration?.matches_to_display;

  const { isLoadingMatches, matches, isValidatingMatches } = useMatches({
    query: `?organizationSlug=${organizationSlug}&programSlug=${programSlug}${
      matchesToDisplay ? `&pageLimit=${matchesToDisplay}` : ''
    }`,
  });

  const { organization } = useOrganization({ organizationSlug });

  const handleRegistrationLimitReachedModalAction = useCallback(() => {
    navigate('/relationships');
  }, [navigate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 6000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!isLoading) return;
    const timer = setTimeout(() => {
      loadingMessageIndex !== 3
        ? setLoadingMessageIndex(loadingMessageIndex + 1)
        : setLoadingMessageIndex(0);
    }, 3000);
    return () => clearTimeout(timer);
  }, [loadingMessageIndex, isLoading]);

  const programType = program?.program_details?.program_type;

  useEffect(() => {
    if (matches?.length === 0) {
      trackScreenTime('Trainee Program Registration - No guides found', {
        'Program type': programType?.program_type_text.common?.verb,
        'Program ID': programSlug,
      });
    } else {
      trackScreenTime('Trainee Program Registration - Select a guide', {
        'Program type': programType?.program_type_text.common?.verb,
        'Program ID': programSlug,
        'Count of available guides': matches?.length,
      });
    }
  }, [
    matches,
    programSlug,
    programType?.program_type_text.common?.verb,
    trackScreenTime,
  ]);

  const isProgramAvailable = program != null;
  const isRoleAvailable = role != null;
  useEffect(() => {
    if (!isProgramAvailable || !isRoleAvailable) {
      navigate('./..');
    }
  }, [isProgramAvailable, isRoleAvailable, navigate]);

  const isGuide = role === 'guide';
  useEffect(() => {
    if (isGuide) {
      navigate(`/programs/${programSlug}`);
    }
  }, [isGuide, navigate, programSlug]);

  const isMembershipTraineeFormEmpty =
    !membershipTraineeForm || Object.keys(membershipTraineeForm).length === 0;
  useEffect(() => {
    if (isMembershipTraineeFormEmpty) {
      navigate('./..');
    }
  }, [isMembershipTraineeFormEmpty, navigate]);

  if (!program) {
    return <></>;
  }

  const programTypeText = programType?.program_type_text;
  const programVariation = programTypeText?.variations?.individual;
  const programRelationshipLimits = program.relationships.relationship_limits;

  // Relationship limits

  const isTraineeRelationshipLimitSet =
    programRelationshipLimits?.enable_trainee_relationship_limit ?? false;

  const isGuideRelationshipLimitSet =
    programRelationshipLimits?.enable_guide_relationship_limit ?? false;

  const traineeRelationshipLimit = isTraineeRelationshipLimitSet
    ? programRelationshipLimits?.trainee_relationship_limit
    : undefined;

  const guideRelationshipLimit = isGuideRelationshipLimitSet
    ? programRelationshipLimits?.guide_relationship_limit
    : undefined;

  const programRegistrationTraineeGuideLimitModal =
    programTypeText?.variations?.individual?.registration?.registration_trainee
      ?.registration_trainee_guide_limit_modal;

  const programRelationships = relationships?.filter((relationship) => {
    const relationshipTraineeProfileIds = (
      relationship.traineeProfiles as IProfile[]
    ).map((relationship) => relationship.id);

    const relationshipIncludesTrainee =
      relationshipTraineeProfileIds.includes(profileId);
    return (
      relationship.programSlug === programSlug &&
      !relationship.isConcluded &&
      relationshipIncludesTrainee
    );
  });

  const showRegistrationLimitReachedModal =
    traineeRelationshipLimit !== undefined
      ? (programRelationships?.length ?? 0) >= traineeRelationshipLimit
      : false;

  const isLimitModalOpen =
    !isLoadingRelationships &&
    !isLoadingRelationships &&
    !isModalOpen &&
    showRegistrationLimitReachedModal;

  const registrationLoadingMessages =
    programVariation?.registration?.registration_trainee
      ?.registration_trainee_choose_a_guide
      ?.registration_trainee_choose_a_guide_loading_state_messages;
  const loadingMessages = registrationLoadingMessages
    ? Object.values(registrationLoadingMessages)
    : [];

  const loadingResources =
    isLoadingRelationships ||
    isValidatingRelationships ||
    isLoadingMatches ||
    isValidatingMatches;

  const handleLimitReachedAlert = (message: string) => {
    dispatch(
      showAppAlert({
        severity: 'error',
        message: message,
        timeout: 5000,
      }),
    );
  };

  const renderLoadingMessage = loadingMessages.map((loadingMessage, index) => {
    return (
      <Fade
        in={loadingMessageIndex === index}
        key={`loading-message-${index}`}
        timeout={{ enter: 1000, exit: 1000 }}
      >
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ mt: 2, position: 'absolute' }}
        >
          {loadingMessage}
        </Typography>
      </Fade>
    );
  });

  const renderLoading = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          p: 3,
          height: '500px',
        }}
      >
        <CircularProgress color="secondary" size={56} />
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {renderLoadingMessage}
        </Box>
      </Box>
    );
  };

  const renderNoMatches = () => {
    return (
      <>
        <PageCard
          title={
            programVariation?.registration?.registration_trainee
              ?.registration_trainee_no_matches_found
              ?.registration_trainee_no_matches_found_title ?? ''
          }
          subtitle={
            programVariation?.registration?.registration_trainee
              ?.registration_trainee_no_matches_found
              ?.registration_trainee_no_matches_found_description ?? ''
          }
          color="info"
          icon={PersonSearchSharp}
        />
        <Typography
          variant="body2"
          sx={{
            mt: isMobile ? 3 : 5,
            mb: isMobile ? 3 : 2,
            textAlign: isMobile ? 'center' : 'left',
            px: isMobile ? 2 : 3,
          }}
        >
          {
            programVariation?.registration?.registration_trainee
              ?.registration_trainee_no_matches_found
              ?.registration_trainee_no_matches_actions_label
          }
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            px: isMobile ? 2 : 3,
          }}
        >
          <Button
            data-cy="pages-ProgramRegistrationPage-Trainee-TraineeRegistrationChooseGuidePage_navigation-button"
            onClick={() => navigate('./../')}
            variant="contained"
            color="info"
            size="large"
            fullWidth={isMobile}
            sx={{
              mr: isMobile ? 0 : 3,
              mb: isMobile ? 2 : 0,
              width: '230px',
              fontWeight: '600',
              maxWidth: '100%',
            }}
          >
            {baseLanguage?.globals?.common?.edit_profile_button_label}
          </Button>
          <Button
            data-cy="pages-ProgramRegistrationPage-Trainee-TraineeRegistrationChooseGuidePage_learn-button"
            onClick={() => navigate('/learn')}
            variant="outlined"
            color="info"
            size="large"
            fullWidth={isMobile}
            sx={{
              width: '230px',
              fontWeight: '600',
              maxWidth: '100%',
            }}
          >
            {baseLanguage?.globals?.common?.explore_learning_hub}
          </Button>
        </Box>
      </>
    );
  };

  const connectWithGuide = (match?: Partial<IMatchMembership>) => {
    const guideProfile = match?.profile as Partial<IProfile>;
    if (!guideProfile?.id) {
      console.error('No `guideProfile` provided.');
      return;
    }

    setChosenGuide({
      userPicture: match?.userPicture,
      profile: guideProfile,
    });

    reqRelationships(
      {
        method: 'PUT',
        data: {
          guideProfiles: [guideProfile.id],
          traineeProfiles: [profileId],
          programSlug,
          organizationSlug,
          programTypeSlug: programType?.metadata?.id?.current,
          programVariationTypeSlug: EProgramVariation.Individual,
          profileId,
          goals: membershipTraineeGoals,
        },
      },
      {
        onSuccess: (result) => {
          const relationshipData = result?.data;
          const relationship = relationshipData && relationshipData[0];
          if (relationship) {
            dispatch(setMembershipTraineeForm({}));
            const relationshipId = relationship?.id || '';
            setRelationshipId(relationshipId);
            dispatch(showConfetti(true));
            setIsModalOpen(true);
          }
          membershipsRevalidate();
          revalidateGoals();
        },
        populateCache: (result, currentData) => {
          const resultIds = result.data.map((relationship) => relationship.id);
          const filterOutNewRelationships =
            currentData?.data.filter((relationship) => {
              return !resultIds.includes(relationship.id);
            }) ?? [];

          const newCache = {
            ...currentData,
            data: [...filterOutNewRelationships, ...result.data],
          } as ITypedApiResult<IRelationship[]>;

          return newCache;
        },
        onError: (err) => {
          if (err.message?.includes('relationship limit reached')) {
            handleLimitReachedAlert(err.message);
          } else {
            dispatch(
              showAppAlert({
                severity: 'error',
                message: err.message,
                timeout: 5000,
              }),
            );
          }
        },
      },
    );
  };

  const renderGuide = (match: IMatch, index: number) => {
    const membership: Partial<IMatchMembership> =
      match.membership as Partial<IMatchMembership>;

    const guideProfile: Partial<IProfile> =
      membership.profile as Partial<IProfile>;

    if (!guideProfile?.id) {
      return;
    }

    const skills = membership.skills as IMatchedSkill[];

    const skillsConfig = program?.registration?.skills;

    const goalCategories = membership.goalCategories as IMatchedGoalCategory[];

    const goalsSource =
      program?.registration?.goals?.custom_goal_categories ??
      organization?.goal_categories?.categories;

    const goals = goalCategories?.map((goalCategory, index) => {
      const label = goalsSource?.find(
        (g) => g?.goal_category_slug?.current === goalCategory?.fieldSlug,
      )?.goal_category_name;

      return {
        key: `${'label'}-${index}`,
        isMatched: goalCategory?.matched ?? false,
        value: label ?? '',
      };
    });

    const matchGoals =
      membership?.goalCategories && membership?.goalCategories?.length !== 0
        ? {
            title: baseLanguage?.profile_card?.matched_on_goals_label,
            goals,
          }
        : undefined;


    const customFields = getFormattedProgramFields(
      match.membership as IMembership,
      program,
    );

    let spotsLeft: number | undefined;
    if (guideRelationshipLimit) {
      spotsLeft = guideRelationshipLimit - (match.relationshipCount ?? 0);
    }
    const image = getProfileImage({
      profilePicture: guideProfile.picture,
      userPicture: membership.userPicture,
    });

    const profileVideo = baseLanguage?.profile_card;

    const organizationFieldsToShow = getVisibleProfileFields(
      organization?.profile_fields,
      guideProfile.organizationFields as ICustomField[],
    );

    const fields = organizationFieldsToShow?.map((field) => {
      return {
        icon: <SanityIcon name={field?.icon?.name as MDIcons} />,
        label: field?.label?.toString() ?? '',
      };
    });

    const location = `${guideProfile.townOrCity ?? ''}${
      guideProfile.townOrCity && guideProfile.country ? ', ' : ''
    }${
      guideProfile.country ? countries.getName(guideProfile.country, 'en') : ''
    }`;

    return (
      <OrganizationProfileView
        key={guideProfile.id}
        profileFields={fields}
        sx={{
          mb: 3,
          mx: isMobile ? 2 : 0,
        }}
        guideReviews={{
          reviewsPlural: profileVideo?.reviews_plural,
          reviewsSingular: profileVideo?.reviews_singular,
          buttonLabel: profileVideo?.reviews_button_label,
          noReviewsText: profileVideo?.no_reviews_yet_label,
          reviews: match.reviews,
        }}
        displayName={guideProfile.displayName ?? ''}
        isBestMatch={index === 0}
        bestMatchLabel={
          baseLanguage?.programs?.program_registration?.best_match_label
        }
        subtitles={[
          guideProfile?.jobTitle && {
            text: guideProfile.jobTitle,
            variant: 'subtitle1',
          },
          location && {
            text: location,
            variant: 'caption',
            color: 'text.secondary',
          },
        ]}
        avatarSrc={image && image}
        goals={matchGoals}
        skills={{
          title: profileVideo?.matched_on_skills_label,
          skills: skills.map((skill, index) => {
            const label =
              skillsConfig?.options?.find(
                (option) => option.id.current === skill.fieldSlug,
              )?.label ?? undefined;

            return {
              key: `${label}-${index}`,
              isMatched: skill.matched ?? false,
              value: label ?? '',
            };
          }),
        }}
        linkedInUrl={guideProfile.linkedInUrl}
        viewLinkedInLabel={
          baseLanguage?.programs?.program_registration
            ?.linkedin_profile_button_label
        }
        buttons={[
          {
            label: interpolate(
              programTypeText?.variations?.individual?.registration
                ?.registration_trainee?.registration_trainee_choose_a_guide
                ?.registration_trainee_choose_a_guide_choose_guide_button_label ??
                '',
              { name: guideProfile.firstName ?? '' },
            ),
            key: '1',
            variant: 'contained',
            color: 'info',
            sx: { px: 3 },
            loading:
              (isMutatingRelationships || loadingResources) &&
              index === selectedIndex,
            disabled:
              (isLoadingRelationships || isMutatingRelationships) &&
              index !== selectedIndex,
            onClick: async () => {
              setSelectedIndex(index);
              await connectWithGuide(membership);
              setSelectedIndex(undefined);
            },
          },
        ]}
        bottomText={
          spotsLeft &&
          guideRelationshipLimit &&
          interpolate(profileVideo?.spots_left_label, {
            guideSingular: programTypeText?.common?.guide?.singular ?? '',
            spotNumber: spotsLeft.toString(),
            spot:
              spotsLeft === 1
                ? profileVideo?.spot_singular
                : profileVideo?.spots_plural,
          })
        }
        customFields={customFields as IProfileViewField[]}
      />
    );
  };

  const renderFoundGuide = () => {
    const renderGuides = () =>
      matches?.map((match, index) => renderGuide(match, index));

    return (
      <>
        <PageCard
          title={
            programVariation?.registration?.registration_trainee
              ?.registration_trainee_choose_a_guide
              ?.registration_trainee_choose_a_guide_title ?? ''
          }
          subtitle={
            programVariation?.registration?.registration_trainee
              ?.registration_trainee_choose_a_guide
              ?.registration_trainee_choose_a_guide_description ?? ''
          }
          color="success"
          cardSx={{ mx: isMobile ? 2 : 0, mb: 3 }}
        />
        <ThemeProvider theme={{ ...theme.appTheme, palette: combinedPalette }}>
          {renderGuides()}
        </ThemeProvider>
      </>
    );
  };

  const renderContent = () => {
    if (matches?.length === 0) {
      return renderNoMatches();
    }

    return renderFoundGuide();
  };

  const handleViewRelationship = () => {
    dispatch(hideConfetti(true));
    setIsModalOpen(false);
    trackScreenTime('Trainee Program Registration - View relationship');
    navigate(`/relationships/${relationshipId}`);
  };

  if (!program || !role) {
    return <></>;
  }
  if (isGuide) {
    return <></>;
  }
  if (isMembershipTraineeFormEmpty) {
    return <></>;
  }

  const image = getProfileImage({
    profilePicture: chosenGuide?.profile?.picture,
    userPicture: chosenGuide?.userPicture,
  });

  return (
    <>
      {isLoading || isLoadingMatches ? renderLoading() : renderContent()}
      <RegistrationLimitReachedModal
        open={isLimitModalOpen}
        title={
          programRegistrationTraineeGuideLimitModal?.registration_trainee_guide_limit_modal_title ??
          ''
        }
        content={
          programRegistrationTraineeGuideLimitModal?.registration_trainee_guide_limit_modal_description ??
          ''
        }
        action={{
          label:
            programRegistrationTraineeGuideLimitModal?.registration_trainee_guide_limit_modal_button_label ??
            '',
          action: handleRegistrationLimitReachedModalAction,
          variant: 'contained',
          color: 'info',
        }}
      />
      <Modal open={isModalOpen} aria-labelledby="modal-high-five">
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Card
            sx={{
              width: '530px',
              minHeight: '380px',
              maxwidth: '95%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              py: 6,
              px: 3,
              boxSizing: 'border-box',
            }}
          >
            <Box
              sx={{
                top: '-60px',
                left: '-15%',
                position: 'absolute',
                width: '130%',
                minHeight: '300px',
                borderRadius: '100%',
                backgroundColor: `rgba(${hexToRGB(
                  combinedPalette.info.main,
                )}, 0.08)`,
              }}
            />
            <Typography
              variant="h4"
              component="p"
              sx={{ mb: 0.5, textAlign: 'center' }}
            >
              {
                programVariation?.registration?.registration_trainee
                  ?.registration_trainee_relationship_created_successfully
                  ?.registration_trainee_relationship_created_successfully_title
              }
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
              {interpolate(
                programVariation?.registration?.registration_trainee
                  ?.registration_trainee_relationship_created_successfully
                  ?.registration_trainee_relationship_created_successfully_description ??
                  '',
                {
                  firstName: chosenGuide?.profile?.firstName || '',
                  lastName: chosenGuide?.profile?.lastName || '',
                },
              )}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                my: 3,
                mb: 4.5,
                zIndex: 2,
              }}
            >
              <Card
                sx={{
                  backgroundColor: '#FFFFFF',
                  p: 0.25,
                  mr: -1,
                  zIndex: 3,
                }}
              >
                <AvatarUser sx={{ width: 112, height: 112 }} />
              </Card>
              <Box
                sx={{
                  borderRadius: '50%',
                  backgroundColor: '#E8F4EC',
                  border: '1px solid #FFFFFF',
                  width: '101px',
                  height: '101px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 2,
                }}
              >
                <Box
                  component="img"
                  src={buildSanityImageUrl({
                    source:
                      settings?.static_media?.relationships
                        ?.relationship_created ?? '',
                    width: 100,
                  })}
                ></Box>
              </Box>
              <Card
                sx={{
                  backgroundColor: '#FFFFFF',
                  p: 0.25,
                  ml: -1,
                  zIndex: 1,
                }}
              >
                <Avatar
                  src={image}
                  sx={{ height: '112px', width: '112px' }}
                  variant="rounded"
                />
              </Card>
            </Box>
            <Button
              data-cy="pages-ProgramRegistrationPage-Trainee-TraineeRegistrationChooseGuidePage_view-relationship-button"
              variant="contained"
              color="info"
              size="large"
              onClick={handleViewRelationship}
            >
              {baseLanguage?.globals?.relationships?.view_relationship}
            </Button>
          </Card>
        </Box>
      </Modal>
    </>
  );
};
