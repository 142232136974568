// external
import PersonSearchSharp from '@mui/icons-material/PersonSearchSharp';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Fade,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// internal

// components
import { GroupCard, PageCard } from 'components';

// store
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectMembershipTraineeForm } from 'store/slices/formsSlice';

// hooks
import {
  useLocalization,
  useMatches,
  useMobileMediaQuery,
  useTrackEvent,
} from 'hooks';

// types
import {
  ICustomField,
  IMatch,
  IMembership,
  IProfile,
  ISkill,
} from '@guider-global/shared-types';

import { getSubDomain } from '@guider-global/front-end-utils';
import {
  useBaseLanguage,
  useOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import { showAppAlert, showConfetti, toggleModal } from 'store/slices/appSlice';
import { useProfiles, useRelationships } from '@guider-global/front-end-hooks';

export const TraineeRegistrationChooseGroupPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  const { profile } = useProfiles({});

  const { reqRelationships, isLoadingRelationships } = useRelationships({});

  const theme = useTheme();
  const isMobile = useMobileMediaQuery();

  // Organization
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);

  // Base language
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const navigate = useNavigate();

  const { programSlug = '', role } = useParams<{
    programSlug: string;
    role: 'guide' | 'trainee';
  }>();

  const membershipTraineeForm = useAppSelector((state) =>
    selectMembershipTraineeForm(state, programSlug),
  );

  useTrackEvent({
    track: {
      eventKey: 'programs-trainee-viewed-group-list',
      eventParams: {
        organization: { organizationSlug },
        program: { organizationSlug, programSlug, includeChooseGuideUrl: true },
      },
    },
  });

  const { getProgram } = useOrganizationPrograms({
    organizationSlug,
    localeCode,
  });
  const program = getProgram(programSlug);

  const matchesToDisplay = program?.registration?.matches_to_display;

  const { isLoadingMatches, matches } = useMatches({
    options: { keepPreviousData: false },
    query: `?organizationSlug=${organizationSlug}&programSlug=${programSlug}${
      matchesToDisplay ? `&pageLimit=${matchesToDisplay}` : ''
    }`,
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 6000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!isLoading) return;
    const timer = setTimeout(() => {
      loadingMessageIndex !== 3
        ? setLoadingMessageIndex(loadingMessageIndex + 1)
        : setLoadingMessageIndex(0);
    }, 3000);
    return () => clearTimeout(timer);
  }, [loadingMessageIndex, isLoading]);

  useEffect(() => {
    if (!program || !role) {
      navigate('./..');
    }
  }, [navigate, program, role]);

  const isGuide = role === 'guide';
  useEffect(() => {
    if (isGuide) {
      navigate(`/programs/${programSlug}`);
    }
  }, [isGuide, navigate, programSlug]);

  const isMembershipTraineeFormEmpty =
    !membershipTraineeForm || Object.keys(membershipTraineeForm).length === 0;
  useEffect(() => {
    if (isMembershipTraineeFormEmpty) {
      navigate('./..');
    }
  }, [isMembershipTraineeFormEmpty, navigate]);

  if (!program || !role) {
    return <></>;
  }

  const maxSpots = program.relationships.group_settings?.max_trainees ?? 0;

  const filteredMatches = matches?.filter((match) => {
    const traineeProfiles = match.relationship?.traineeProfiles ?? [];
    const spotsOccupied = traineeProfiles.length;
    const spotsLeft = maxSpots - spotsOccupied;

    return spotsLeft > 0;
  });

  if (isGuide) {
    return <></>;
  }

  if (isMembershipTraineeFormEmpty) {
    return <></>;
  }

  const programType = program.program_details?.program_type;
  const programVariation = programType?.program_type_text?.variations?.group;

  const registrationLoadingMessages =
    programVariation?.registration?.registration_trainee
      ?.registration_trainee_choose_a_guide
      ?.registration_trainee_choose_a_guide_loading_state_messages;
  const loadingMessages = registrationLoadingMessages
    ? Object.values(registrationLoadingMessages)
    : [];

  const renderLoadingMessage = loadingMessages.map((loadingMessage, index) => {
    return (
      <Fade
        in={loadingMessageIndex === index}
        key={`loading-message-${index}`}
        timeout={{ enter: 1000, exit: 1000 }}
      >
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ mt: 2, position: 'absolute' }}
        >
          {loadingMessage}
        </Typography>
      </Fade>
    );
  });

  const renderLoading = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          p: 3,
          height: '500px',
        }}
      >
        <CircularProgress color="secondary" size={56} />
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {renderLoadingMessage}
        </Box>
      </Box>
    );
  };

  const renderNoMatches = () => {
    return (
      <>
        <PageCard
          title={
            programVariation?.registration?.registration_trainee
              ?.registration_trainee_no_matches_found
              ?.registration_trainee_no_matches_found_title ?? ''
          }
          subtitle={
            programVariation?.registration?.registration_trainee
              ?.registration_trainee_no_matches_found
              ?.registration_trainee_no_matches_found_description ?? ''
          }
          color="info"
          icon={PersonSearchSharp}
        />
        <Typography
          variant="body2"
          sx={{
            mt: isMobile ? 3 : 5,
            mb: isMobile ? 3 : 2,
            textAlign: isMobile ? 'center' : 'left',
            px: isMobile ? 2 : 3,
          }}
        >
          {
            programVariation?.registration?.registration_trainee
              ?.registration_trainee_no_matches_found
              ?.registration_trainee_no_matches_actions_label
          }
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            px: isMobile ? 2 : 3,
          }}
        >
          <Button
            data-cy="pages-ProgramRegistrationPage-Trainee-TraineeRegistrationChooseGroupPage_navigation-button"
            onClick={() => navigate('./../')}
            variant="contained"
            color="info"
            size="large"
            fullWidth={isMobile}
            sx={{
              mr: isMobile ? 0 : 3,
              mb: isMobile ? 2 : 0,
              width: '230px',
              fontWeight: '600',
              maxWidth: '100%',
            }}
          >
            {baseLanguage?.globals?.common?.edit_profile_button_label}
          </Button>
          <Button
            data-cy="pages-ProgramRegistrationPage-Trainee-TraineeRegistrationChooseGroupPage_learn-button"
            onClick={() => navigate('/learn')}
            variant="outlined"
            color="info"
            size="large"
            fullWidth={isMobile}
            sx={{
              width: '230px',
              fontWeight: '600',
              maxWidth: '100%',
            }}
          >
            {baseLanguage?.globals?.common?.explore_learning_hub}
          </Button>
        </Box>
      </>
    );
  };

  const joinGroup = async (relationshipId: string) => {
    const relationshipResponse = await reqRelationships({
      method: 'PATCH',
      url: `/relationships/${relationshipId}`,
      // TODO fix Types
      // @ts-ignore
      data: {
        traineeProfiles: profile?.id ? [profile.id] : [],
      },
    });

    if (relationshipResponse.status === 'error') {
      return dispatch(
        showAppAlert({
          severity: 'error',
          message: relationshipResponse.errors?.[0].message,
          timeout: 5000,
        }),
      );
    }

    const relationshipData = relationshipResponse?.data;
    const relationship = relationshipData?.[0];

    if (!relationship) {
      return;
    }

    await reqRelationships({ url: '/relationships' });

    navigate(`/relationships/${relationship.id}`);
    dispatch(showConfetti(true));
    dispatch(toggleModal('traineeModal'));
  };

  const renderGroup = (match: IMatch, index: number) => {
    const membership: Partial<IMembership> =
      match.membership as Partial<IMembership>;

    const traineeProfiles: string[] | undefined =
      match.relationship?.traineeProfiles;

    const relationshipId = match.relationship?.id;

    if (!relationshipId) {
      console.error('relationshipId missing');
      return;
    }

    const guideProfile: Partial<IProfile> =
      membership.profile as Partial<IProfile>;

    if (!guideProfile?.id) {
      return;
    }
    const skills: Partial<ISkill>[] = membership.skills as Partial<ISkill>[];
    const skillsSlugs = skills.map((skill) => skill.fieldSlug ?? '');

    const programFields: Partial<ICustomField>[] =
      membership.programFields as Partial<ICustomField>[];

    const programFieldsFormatted = Object.fromEntries(
      programFields.map((programField) => [
        programField.fieldSlug,
        programField.value,
      ]),
    );

    return (
      <GroupCard
        userRole={role}
        traineeProfiles={traineeProfiles}
        guide={guideProfile}
        data={{
          skills: skillsSlugs,
          ...programFieldsFormatted,
          title: match?.relationship?.title,
          description: match?.relationship?.description,
        }}
        key={`guide-card-${index}`}
        isBestMatch={index === 0}
        action={() => joinGroup(relationshipId)}
        blurred={index !== 0}
        isLoading={isLoadingRelationships}
      />
    );
  };

  const renderFoundGuide = () => {
    const renderGroups = () =>
      filteredMatches?.map((match, index) => renderGroup(match, index));

    return (
      <>
        <PageCard
          title={
            programVariation?.registration?.registration_trainee
              ?.registration_trainee_choose_a_guide
              ?.registration_trainee_choose_a_guide_title ?? ''
          }
          subtitle={
            programVariation?.registration?.registration_trainee
              ?.registration_trainee_choose_a_guide
              ?.registration_trainee_choose_a_guide_description ?? ''
          }
          color="success"
        />
        <Divider sx={{ my: 4, mx: isMobile ? 2 : 3 }} />
        {renderGroups()}
      </>
    );
  };

  const renderContent = () => {
    if (filteredMatches?.length === 0) {
      return renderNoMatches();
    }

    return renderFoundGuide();
  };

  return (
    <Paper
      sx={{
        pt: isMobile ? 0 : 4,
        pb: 4,
        borderTop: `8px solid ${theme.palette.secondary.main}`,
        ...(isMobile && { borderRadius: '0px' }),
      }}
    >
      {isLoading || isLoadingMatches ? renderLoading() : renderContent()}
    </Paper>
  );
};
